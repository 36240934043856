<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
            <!-- Dashboard Box -->
        <b-card no-body class="mb-0">
                       <div class="m-2">
                <!-- Table Top -->
                <b-row>

                    <b-col clos="12" md="4">
                        <b-form-group label="Current Password" class="">
                        <b-form-input placeholder="Enter Current Password" type="password" v-model="current_password" class="form-control-merge"/>
                        </b-form-group>
                    </b-col>

                      <b-col clos="12" md="4">
                        <b-form-group label="New Password" class="">
                            <b-form-input placeholder="Enter New Password" type="password" v-model="new_password" class="form-control-merge"/>
                        </b-form-group>
                    </b-col>

                      <b-col clos="12" md="4">
                        <b-form-group label="Re-enter New Password" class="">
                            <b-form-input placeholder="Re-enter New Password" type="password" v-model="repeat_new_password" class="form-control-merge"/>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>

            <b-col cols="12" sm="12" class="d-flex align-items-center justify-content-center">
        <b-button variant="warning" class="mt-1" @click="changePassword">
            <span class="text-nowrap">Save Changes</span> 
        </b-button>
            </b-col>

                </b-row>
            </div>

        </b-card>
            
    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";
import Bus from "../../../event-bus";

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,

            current_password: '',
            new_password: '',
            repeat_new_password: '',
        }
    },
    methods : {
        changePassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        id: this.$store.getters.currentUser._id,
                        user_name: this.$store.getters.currentUser.user_name,
                        current_password: this.current_password,
                        new_password: this.new_password,
                        repeat_new_password: this.repeat_new_password,
                    },
                    api:'/api/change-password'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.current_password = "";
                        this.new_password = "";
                        this.repeat_new_password = "";
                    
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password successfully changed',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/login');
                        });
                    }        
                }); 
        },
        breadCrumb(){
          var item = [{
            to:{name:'manage-order'},
            text: 'Manage Order',
          },,{
            to:null,
            text:'Change Password',
            active:true
          }];
          return item;
        },
       
    },
    
    mounted(){
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
